<template>
  <div class="header-container">
    <div class="search-warpper">
      <el-form :inline="true" :model="query">
        <el-form-item label="公告标题：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入公告标题"
            v-model="query.titleLike"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit('search')"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  .search-warpper {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #1a1a1a;
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
  }
}
</style>
